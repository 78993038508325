import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Наші послуги | Оренда TrailBlaze - ваша пригода, наш досвід
			</title>
			<meta name={"description"} content={"Комплексні послуги з прокату позашляховиків, пристосовані до ваших потреб у пригодах"} />
			<meta property={"og:title"} content={"Наші послуги | Оренда TrailBlaze - ваша пригода, наш досвід"} />
			<meta property={"og:description"} content={"Комплексні послуги з прокату позашляховиків, пристосовані до ваших потреб у пригодах"} />
			<meta property={"og:image"} content={"https://biz.vortexialbiz.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://biz.vortexialbiz.com/img/0569345.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://biz.vortexialbiz.com/img/0569345.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://biz.vortexialbiz.com/img/0569345.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://biz.vortexialbiz.com/img/0569345.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://biz.vortexialbiz.com/img/0569345.png"} />
			<meta name={"msapplication-TileImage"} content={"https://biz.vortexialbiz.com/img/0569345.png"} />
			<meta name={"msapplication-TileColor"} content={"https://biz.vortexialbiz.com/img/0569345.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 0px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Наші послуги
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			У TrailBlaze Rentals ми пишаємося тим, що пропонуємо широкий спектр послуг, покликаних покращити ваші враження від бездоріжжя. Наша місія - надати все необхідне для незабутньої пригоди, від високоякісних транспортних засобів до експертного керівництва та підтримки. Незалежно від того, чи ви шукаєте пригод наодинці, чи у складі групи, наші послуги розроблені таким чином, щоб забезпечити вам найкращі враження.
			</Text>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://biz.vortexialbiz.com/img/7.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Наш автопарк
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					Наш автопарк - це основа TrailBlaze Rentals, що включає різноманітний вибір квадроциклів та квадроциклів, які відповідають вподобанням та рівню досвіду кожного гонщика. Кожен транспортний засіб ретельно обслуговується, щоб забезпечити максимальну продуктивність та надійність на стежках.
					<br/><br/>
Квадроцикли: Наші всюдиходи ідеально підходять для водіїв-одинаків, які шукають гострих відчуттів та пригод.
<br/>
     Висока продуктивність: Кожен квадроцикл оснащений потужними двигунами для оптимальної продуктивності.
<br/>
     Функції безпеки: Наші квадроцикли оснащені вдосконаленими функціями безпеки, включаючи контроль стійкості та надійні системи підвіски.
<br/>
UTV: Ідеально підходять для груп або сімей, наші автомобілі підвищеної прохідності пропонують комфорт і функціональність.
<br/>
     Просторі сидіння: UTV надають достатньо місця для пасажирів і вантажу, забезпечуючи комфортну поїздку.
<br/>
     Покращений контроль: Завдяки таким функціям, як гідропідсилювач керма та повний привід, наші квадроцикли з легкістю долають будь-яку місцевість.

				</Text>
				
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Індивідуальні пакети
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					Ми розуміємо, що кожна пригода унікальна. Саме тому ми пропонуємо індивідуальні пакети прокату, що відповідають вашим потребам.
					<br/><br/>
Гнучкі терміни оренди: Обирайте з різних періодів оренди, від погодинного до повного дня.
<br/>
     Зручність: Гнучкий час забирання та вивезення відповідно до вашого графіку.
<br/>
     Доступність: Конкурентоспроможні ціни відповідно до вашого бюджету.
<br/>
Групові пакети: Спеціальні пакети для сімей, друзів та корпоративних груп.
<br/>
    Знижки: Скористайтеся груповими знижками та спеціальними цінами для великих вечірок.
<br/>
    Індивідуальні враження: Індивідуальні маршрути, що відповідають інтересам та рівню підготовки вашої групи.

				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://biz.vortexialbiz.com/img/8.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://biz.vortexialbiz.com/img/9.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Безпека та підтримка
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					У TrailBlaze Rentals ваша безпека є нашим головним пріоритетом. Ми пропонуємо комплексну підтримку, щоб забезпечити безпечний та приємний досвід для всіх наших клієнтів.
					<br/><br/>
Захисне спорядження: Ми надаємо все необхідне захисне спорядження, включаючи каски, рукавички та захисний одяг.
<br/>
     Забезпечення якості: Наше спорядження регулярно перевіряється та обслуговується за найвищими стандартами.
<br/>
     Підгонка та комфорт: Кожна модель доступна в різних розмірах, щоб забезпечити ідеальну посадку для кожного велосипедиста.
<br/>
Експертне керівництво: Наша команда досвідчених гідів готова допомогти вам на кожному кроці.
<br/>
     Брифінги: Детальні інструктажі з техніки безпеки та інструкції з катання надаються перед кожним прокатом.
<br/>
     Екскурсії з гідом: Для тих, хто шукає екскурсії, наші досвідчені гіди проведуть тури найкращими стежками.

				</Text>
				<Link
					href="/contact-us"
					color="--primary"
					font="--lead"
					text-decoration-line="initial"
					hover-text-decoration-line="underline"
				>
					Розпочніть свою пригоду зараз!
				</Link>
			</Box>
		</Section>
		<Components.Images />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});